import { useProfileRepository } from '@/composables/repository/useProfileRepository'
import { useAvatarRepository } from '@/composables/repository/useAvatarRepository'
import { useHousingRepository } from '@/composables/repository/useHousingRepository'
import { useHousingWorldsRepository } from '@/composables/repository/useHousingWorldsRepository'
import { useAchievementRepository } from '@/composables/repository/useAchievementRepository'
import { useTemporaryRepository } from '@/composables/repository/useTemporaryRepository'
import { useRelationshipRepository } from '@/composables/repository/useRelationshipRepository'
import { useMessageRoomRepository } from '@/composables/repository/useMessageRoomRepository'
import { useBlocksRepository } from '@/composables/repository/useBlocksRepository'
import { useNotificationRepository } from '@/composables/repository/useNotificationRepository'
import { useMyVketEventRepository } from '@/composables/repository/useMyVketEventRepository'
import { useCloudStoreRepository } from '@/composables/repository/useCloudStoreRepository'
import { useWorldPortalRepository } from '@/composables/repository/useWorldPortalRepository'
import { useWorldLikeRepository } from '@/composables/repository/useWorldLikeRepository'
import { useWorldPortalLikeRepository } from '@/composables/repository/useWorldPortalLikeRepository'
import { useVisitWorldRepository } from '@/composables/repository/useVisitWorldRepository'
import { useTutorialRepository } from '@/composables/repository/useTutorialRepository'
import { useHomeRepository } from '@/composables/repository/useHomeRepository'
import { useAssetRepository } from '@/composables/repository/useAssetRepository'
import { useChannelSessionRepository } from '@/composables/repository/useChannelSessionRepository'
import { useNewsRepository } from '@/composables/repository/useNewsRepository'
import { useStripeRepository } from '@/composables/repository/useStripeRepository'
import { usePresentRepository } from '@/composables/repository/usePresentRepository'
import { usePaypalRepository } from '@/composables/repository/usePaypalRepository'
import { useContactRepository } from '@/composables/repository/useContactRepository'
import { useWebPushRepository } from '@/composables/repository/useWebPushRepository'
import { useSalesRepository } from '@/composables/repository/useSalesRepository'
import { useTransferRepository } from '@/composables/repository/useTransferRepository'
import { useVketBoxRepository } from '@/composables/repository/useVketBoxRepository'

export const useRepositoryFactory = () => {
  const config = useRuntimeConfig()
  type Repository = {
    get: {
      [key: string]: unknown | undefined
    }
    post: {
      [key: string]: unknown | undefined
    }
    put: {
      [key: string]: unknown | undefined
    }
    delete: {
      [key: string]: unknown | undefined
    }
  }

  type Repositories = {
    [key: string]: Repository
  }

  // TODO: これ関数かまさず型チェックの恩恵だけ受けたい（repositories が Repositories であることは満たしたい）
  function i<T extends Repositories>(o: T): T {
    return o
  }

  const repositories = i({
    profile: useProfileRepository(),
    avatar: useAvatarRepository(),
    housing: useHousingRepository(),
    housingWorlds: useHousingWorldsRepository(),
    achievement: useAchievementRepository(),
    temporary: useTemporaryRepository(),
    room: useMessageRoomRepository(),
    relationship: useRelationshipRepository(),
    blocks: useBlocksRepository(),
    notification: useNotificationRepository(),
    myVketEvent: useMyVketEventRepository(),
    cloudStore: useCloudStoreRepository(),
    worldPortal: useWorldPortalRepository(),
    worldLike: useWorldLikeRepository(),
    worldPortalLike: useWorldPortalLikeRepository(),
    visitWorld: useVisitWorldRepository(),
    tutorial: useTutorialRepository(),
    home: useHomeRepository(),
    asset: useAssetRepository(),
    channelSessionRepository: useChannelSessionRepository(),
    news: useNewsRepository(),
    stripe: useStripeRepository(),
    present: usePresentRepository(),
    paypal: usePaypalRepository(),
    contact: useContactRepository(),
    webPush: useWebPushRepository(),
    sales: useSalesRepository(),
    transfer: useTransferRepository(),
    vketBox: useVketBoxRepository(),
  } as const)

  return {
    get: <K extends keyof typeof repositories>(name: K) => repositories[name],
  }
}
