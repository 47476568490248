import { z } from 'zod'
import { EMOTE_TYPE, FACIAL_TYPE } from '@/composables/useVrmViewer'

export const UPDATE_MODEL = {
  VRM: 'vrm',
  GLB: 'glb',
} as const
export const updateModel = z.enum([UPDATE_MODEL.VRM, UPDATE_MODEL.GLB])
export type UpdateModel = z.infer<typeof updateModel>

export const STATUS = {
  NOT_PUBLISHED: 'not_published',
  IN_REVIEW: 'in_review',
  PUBLISHED: 'published',
  REVIEW_APPROVED: 'review_approved',
  REVIEW_REJECTED: 'review_rejected',
} as const
export const status = z.enum([
  STATUS.NOT_PUBLISHED,
  STATUS.IN_REVIEW,
  STATUS.PUBLISHED,
  STATUS.REVIEW_APPROVED,
  STATUS.REVIEW_REJECTED,
])
export type Status = z.infer<typeof status>

export const CONVERT_STATUS = {
  NOT_CONVERTED: 'not_converted',
  CONVERTING: 'converting',
  CONVERTED: 'converted',
  ERROR: 'error',
} as const

const convertStatus = z.enum([
  CONVERT_STATUS.NOT_CONVERTED,
  CONVERT_STATUS.CONVERTING,
  CONVERT_STATUS.CONVERTED,
  CONVERT_STATUS.ERROR,
])

export type ConvertStatus = z.infer<typeof convertStatus>

export const vketBoxEmoteType = {
  [EMOTE_TYPE.STANDING]: 0,
  [EMOTE_TYPE.STANDING_5]: 1,
  [EMOTE_TYPE.STANDING_TAKE_PHOTO]: 2,
  [EMOTE_TYPE.STANDING_CUTE]: 3,
  [EMOTE_TYPE.STANDING_COOL]: 4,
} as const
const isVketBoxEmoteTypeKey = (
  key: string
): key is keyof typeof vketBoxEmoteType =>
  Object.prototype.hasOwnProperty.call(vketBoxEmoteType, key)

// Object.keysの型をunion型に変換する
const getKeys = <T extends { [key: string]: unknown }>(obj: T): (keyof T)[] => {
  return Object.keys(obj)
}

export const formatVketBoxEmoteTypeId = (emoteType: string) => {
  if (!isVketBoxEmoteTypeKey(emoteType)) return
  return vketBoxEmoteType[emoteType]
}
export const formatVketBoxEmoteType = (emoteId: number) =>
  getKeys(vketBoxEmoteType).find(
    (key) => isVketBoxEmoteTypeKey(key) && vketBoxEmoteType[key] === emoteId
  )

export const vketBoxFacialType = {
  [FACIAL_TYPE.joy]: 0,
  [FACIAL_TYPE.angry]: 1,
  [FACIAL_TYPE.sorrow]: 2,
  [FACIAL_TYPE.fun]: 3,
  [FACIAL_TYPE.neutral]: 4,
} as const
const isVketBoxFacialTypeKey = (
  key: string
): key is keyof typeof vketBoxFacialType =>
  Object.prototype.hasOwnProperty.call(vketBoxFacialType, key)

export const formatVketBoxFacialTypeId = (facialType: string) => {
  if (!isVketBoxFacialTypeKey(facialType)) return
  return vketBoxFacialType[facialType]
}
export const formatVketBoxFacialType = (facialId: number) =>
  getKeys(vketBoxFacialType).find(
    (key) => isVketBoxFacialTypeKey(key) && vketBoxFacialType[key] === facialId
  )

export const vketBoxSubmission = z.object({
  id: z.number(),
  status,
  convertStatus,
  thumbnailUrl: z.string().nullable(),
  modelUrl: z.string().nullable(),
  poseId: z.number().nullable(),
  faceId: z.number().nullable(),
  name: z.string().nullable(),
  author: z.string().nullable(),
  website: z.string().nullable(),
  comment: z.string().nullable(),
  createdAt: z.union([z.instanceof(Date), z.string()]), // date
  updatedAt: z.union([z.instanceof(Date), z.string()]), // date
})

export type VketBoxSubmission = z.infer<typeof vketBoxSubmission>

export const vketBoxSubmissionDetail = z.object({
  id: z.number(),
  status,
  convertStatus,
  thumbnailUrl: z.string().nullable(),
  modelUrl: z.string().nullable(),
  poseId: z.number().nullable(),
  faceId: z.number().nullable(),
  name: z.string().nullable(),
  author: z.string().nullable(),
  website: z.string().nullable(),
  comment: z.string().nullable(),
  reviews: z.array(
    z.object({
      result: z.enum(['approved', 'rejected']),
      rejectedReason: z.string().nullable(),
    })
  ),
})

export type VketBoxSubmissionDetail = z.infer<typeof vketBoxSubmissionDetail>

export const vketBoxSubmissionListItem = z.object({
  id: z.number(),
  status,
  thumbnailUrl: z.string().nullable(),
  name: z.string().nullable(),
})

export type VketBoxSubmissionListItem = z.infer<
  typeof vketBoxSubmissionListItem
>
