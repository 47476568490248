import { z } from 'zod'
import { iconResponse, thumbnail } from '@/models/file'
import { item as storeItem } from '@/models/cloudstore'

export const PRESENT_TYPE = {
  UNOPENED: 'unopened',
  OPENED: 'opened',
} as const

export const presentType = z.enum([PRESENT_TYPE.UNOPENED, PRESENT_TYPE.OPENED])

export type PresentType = z.infer<typeof presentType>

export const present = z.object({
  id: z.number(),
  userId: z.number(),
  subjectId: z.number(),
  subjectType: z.string(),
  openedAt: z.string().nullable(),
  platformCode: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  achievement: z
    .object({
      id: z.number(),
      label: z.string(),
      title: z.string(),
      description: z.string(),
      condition: z.string().nullable(),
      titleEn: z.string(),
      descriptionEn: z.string(),
      conditionEn: z.string().nullable(),
      thumbnail,
      ingameThumbnail: thumbnail,
    })
    .nullable(),
  givenUser: z
    .object({
      id: z.number(),
      vketId: z.string(),
      name: z.string(),
      introduction: z.string(),
      icon: iconResponse,
      topPageAvatarId: z.number().nullable(),
    })
    .nullable(),
  presentStoreItem: z
    .object({
      id: z.number(),
      storeItemId: z.number(),
    })
    .nullable(),
})

export type Present = z.infer<typeof present>

// NOTE: 今後ストアアイテム以外種類のプレゼントが追加される可能性を考慮してoptionalにしています
export const presentWithItemData = present.extend({
  storeItem: storeItem.optional(),
})

export type PresentWithItemData = z.infer<typeof presentWithItemData>
