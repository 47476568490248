import { z } from 'zod'
import { useApi } from './useApi'
import { useShenronApi } from '@/composables/repository/useShenronApi'
import {
  status,
  updateModel,
  vketBoxSubmission,
  vketBoxSubmissionDetail,
  vketBoxSubmissionListItem,
} from '@/models/vketbox'

const prefix = '/api/vketbox'

//* GET *//
// VketBox入稿一覧
export const getVketboxSubmissionsRequest = z.object({
  limit: z.number(),
  offset: z.number(),
})
export type GetVketboxSubmissionsRequest = z.infer<
  typeof getVketboxSubmissionsRequest
>

export const getVketboxSubmissionsResponse = z.object({
  vketboxSubmissions: z.array(vketBoxSubmissionListItem),
  vketboxSubmissionCount: z.number(),
})
export type GetVketboxSubmissionsResponse = z.infer<
  typeof getVketboxSubmissionsResponse
>

// VketBox入稿詳細
export const getVketboxSubmissionDetailRequest = z.object({
  vketboxSubmissionId: z.number(),
})
export type GetVketboxSubmissionDetailRequest = z.infer<
  typeof getVketboxSubmissionDetailRequest
>

export const getVketboxSubmissionDetailResponse = z.object({
  vketboxSubmission: vketBoxSubmissionDetail,
  vketboxStagingSubmission: vketBoxSubmissionDetail.optional(),
})
export type GetVketboxSubmissionDetailResponse = z.infer<
  typeof getVketboxSubmissionDetailResponse
>

export const getVketboxSubmissionDetailsResponse = z.object({
  vketboxDetail: z.object({
    submissionLimitAmount: z.number(),
    submissionCount: z.number(),
  }),
})
export type GetVketboxSubmissionDetailsResponse = z.infer<
  typeof getVketboxSubmissionDetailsResponse
>

//* POST *//
// VketBox入稿モデル登録
export const postVketboxSubmissionsRequest = z.object({
  modelExtension: z.string(),
})
export type PostVketboxSubmissionsRequest = z.infer<
  typeof postVketboxSubmissionsRequest
>
export const postVketboxSubmissionsResponse = z.object({
  vketboxSubmission: vketBoxSubmission,
  uploadUrl: z.string(),
})
export type PostVketboxSubmissionsResponse = z.infer<
  typeof postVketboxSubmissionsResponse
>

// VketBoxモデル入稿完了
export const postVketboxSubmissionsFinishRequest = z.object({
  vketboxSubmissionId: z.number(),
})
export type PostVketboxSubmissionsFinishRequest = z.infer<
  typeof postVketboxSubmissionsFinishRequest
>
export const postVketboxSubmissionsFinishResponse = z.object({
  vketboxSubmission: vketBoxSubmission,
  submissionResult: z.object({
    status: z.number(),
    message: z.string(),
  }),
})
export type PostVketboxSubmissionsFinishResponse = z.infer<
  typeof postVketboxSubmissionsFinishResponse
>

// VketBox審査申請
export const postVketboxSubmissionsReviewRequest = z.object({
  vketboxSubmissionId: z.number(),
})
export type PostVketboxSubmissionsReviewRequest = z.infer<
  typeof postVketboxSubmissionsReviewRequest
>
export const postVketboxSubmissionsReviewResponse = z.object({
  vketboxSubmission: vketBoxSubmission,
})
export type PostVketboxSubmissionsReviewResponse = z.infer<
  typeof postVketboxSubmissionsReviewResponse
>

//* PUT *//
// VketBox入稿情報登録
export const putVketboxSubmissionRequest = z.object({
  thumbnail: z
    .union([z.instanceof(File), z.string()])
    .nullable()
    .optional(),
  // 更新がある場合はtrue, ない場合はundefined
  updateModel: z.boolean().optional(),
  // 更新がある場合はモデルの種類, ない場合はundefined
  updateModelExtension: updateModel.optional(),
  poseId: z.number().optional(),
  faceId: z.number().optional(),
  name: z.string().optional(),
  author: z.string().optional(),
  website: z.string().optional(),
  comment: z.string().optional(),
  status: status.optional(),
})
export type PutVketboxSubmissionsRequest = z.infer<
  typeof putVketboxSubmissionRequest
>
export const putVketboxSubmissionsResponse = z.object({
  vketboxSubmission: vketBoxSubmission,
  modelUploadUrl: z.string().nullable().optional(),
})
export type PutVketboxSubmissionsResponse = z.infer<
  typeof putVketboxSubmissionsResponse
>

//* DELETE *//
export const deleteVketboxSubmissionsResponse = z.object({
  vketboxSubmission: vketBoxSubmission,
})
export type DeleteVketboxSubmissionsResponse = z.infer<
  typeof deleteVketboxSubmissionsResponse
>
export const deleteVketboxSubmissionsReviewResponse = z.object({
  vketboxSubmission: vketBoxSubmission,
})
export type DeleteVketboxSubmissionsReviewResponse = z.infer<
  typeof deleteVketboxSubmissionsReviewResponse
>

export const useVketBoxRepository = () => {
  const api = useApi(prefix)
  const shenronApi = useShenronApi()

  return {
    get: {
      async getVketBoxSubmissions(
        params: GetVketboxSubmissionsRequest
      ): Promise<GetVketboxSubmissionsResponse> {
        const queryContent = {
          limit: params.limit,
          offset: params.offset,
        }

        const query = createUrlSearchParams(queryContent)
        return await shenronApi.get(
          `/vketbox/user/submissions?${query.toString()}`
        )
      },
      async getVketBoxSubmissionsDetail(
        params: GetVketboxSubmissionDetailRequest
      ): Promise<GetVketboxSubmissionDetailResponse> {
        return await shenronApi.get(
          `/vketbox/user/submissions/${params.vketboxSubmissionId}`
        )
      },
      async getVketBoxSubmissionsDetails(): Promise<GetVketboxSubmissionDetailsResponse> {
        return await shenronApi.get(`/vketbox/user/details`)
      },
    },
    post: {
      // モデル登録
      async postVketBoxSubmissions(
        params: PostVketboxSubmissionsRequest
      ): Promise<PostVketboxSubmissionsResponse> {
        return await api.post(`/submissions`, {
          body: params,
        })
      },
      async postVketBoxSubmissionsFinish(
        params: PostVketboxSubmissionsFinishRequest
      ): Promise<PostVketboxSubmissionsFinishResponse> {
        return await api.post(
          `/submissions/${params.vketboxSubmissionId}/finish`,
          {
            body: params,
          }
        )
      },
      async postVketBoxSubmissionsReview(
        params: PostVketboxSubmissionsReviewRequest
      ): Promise<PostVketboxSubmissionsReviewResponse> {
        return await api.post(
          `/submissions/${params.vketboxSubmissionId}/review`
        )
      },
    },
    put: {
      // 情報登録
      async putVketBoxSubmissions(
        params: PutVketboxSubmissionsRequest,
        vketboxSubmissionId: number
      ): Promise<PutVketboxSubmissionsResponse> {
        return await api.put(`/submissions/${vketboxSubmissionId}`, {
          body: objectToFormData(params),
        })
      },
    },
    patch: {},
    delete: {
      deleteVketBoxSubmissions: (
        vketboxSubmissionId: number
      ): Promise<DeleteVketboxSubmissionsResponse> => {
        return api.delete(`/submissions/${vketboxSubmissionId}`)
      },
      deleteVketBoxSubmissionsReview: (
        vketboxSubmissionId: number
      ): Promise<DeleteVketboxSubmissionsReviewResponse> => {
        return api.delete(`/submissions/${vketboxSubmissionId}/review`)
      },
    },
  }
}
